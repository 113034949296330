import * as React from 'react';
import clsx from 'clsx';

import { BoxModel } from 'src/theme';

type Props = {
  children: React.ReactNode;
  className?: string;
};

const PageContainer: React.FC<Props> = ({
  children,
  className,
}: Props) => {
  return (
    <>
      <div className={clsx('page-container', className)}>
        {children}
      </div>
      <style jsx>
        {`
            .page-container {
              padding: ${BoxModel.MarginMain};
              min-height: 200px;
            }
          `}
      </style>
    </>
  );
};

export default PageContainer;
