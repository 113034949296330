import * as React from 'react';
import * as Sentry from '@sentry/nextjs';
import Head from 'next/head';
import Link from 'next/link';
import clsx from 'clsx';
import { NextPageContext } from 'next';

import PageContainer from 'components/layout/PageContainer';
import enCommon from 'public/locales/en/common.json';
import jaCommon from 'public/locales/ja/common.json';
import { BoxModel, Color } from 'src/theme';
import { useRouter } from 'next/router';

// next-i18next の翻訳がstaticな画面では使用できないため、直接翻訳ファイルを参照
const getMessage = (statusCode: number, isLocaleJa: boolean): string => {
  switch (statusCode) {
    case 404:
      return isLocaleJa
        ? jaCommon['error.not.found']
        : enCommon['error.not.found'];
    case 401:
      return isLocaleJa
        ? jaCommon['error.unauthorized']
        : enCommon['error.unauthorized'];
    default:
      return '';
  }
};

type Props = {
  statusCode?: number;
};

const ErrorPage = ({ statusCode = 111 }: Props): React.ReactElement => {
  const router = useRouter();
  const [locale, setLocale] = React.useState(router.locale || 'ja');
  const isLocaleJa = locale === 'ja';
  const onClickLocale = React.useCallback((locale: string) => setLocale(locale), []);
  return (
    <>
      <Head><title>{statusCode}</title></Head>
      <PageContainer>
        <div className="status-code">{statusCode}</div>
        <div className="change-locale">
          <div
            className={clsx('locale', { 'current-locale': isLocaleJa })}
            onClick={() => onClickLocale('ja')}>
            日本語
          </div>
          <div
            className={clsx('locale', { 'current-locale': !isLocaleJa })}
            onClick={() => onClickLocale('en')}>
            English
          </div>
        </div>
        <div className="message">
          <p>{getMessage(statusCode, isLocaleJa)}</p>
          <Link href="/signin" locale={locale}>
            <a>&lt;
              {isLocaleJa
                ? jaCommon['signin.title']
                : enCommon['signin.title']
              }
            </a>
          </Link>
        </div>
      </PageContainer>
      <style jsx>
        {`
          .status-code {
            font-size: 300px;
            height: 300px;
            margin: 100px auto;
            text-align: center;
          }
          .change-locale,
          .message {
            margin: auto;
            width: 600px;
          }
          .change-locale {
            display: flex;
          }
          .locale {
            align-items: center;
            border-radius: ${BoxModel.BorderRadius};
            border: 1px solid ${Color.BorderDefault};
            cursor: pointer;
            display: flex;
            padding: 4px 12px;
            :not(:first-of-type) {
              margin-left: 16px;
            }
            &.current-locale {
              background: ${Color.Primary};
              color: ${Color.BackgroundContent};
            }
          }
        `}
      </style>
    </>
  );
};

ErrorPage.getInitialProps = async ({
  res,
  err,
  asPath,
}: NextPageContext): Promise<Props> => {
  Sentry.captureException(
    new Error(`_error.js getInitialProps missing data at path: ${asPath}`),
  );
  const statusCode = res?.statusCode ?? err?.statusCode;
  return { statusCode };
};

export default ErrorPage;
